import React, { useEffect, useState } from "react";
import { CircularProgress, TextField, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, TableHead, IconButton, TableFooter, Tooltip } from '@mui/material'
import { useDataProvider, useNotify, Button } from "react-admin";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TripRouteDownload } from "./TripRouteDownloadButton";

const RenderTripRoute = ({ routes }) => (
    <Table size="small">
        <TableHead>
            <TableRow selected>
                <TableCell><b>Route Leg</b></TableCell>
                <TableCell><b>Routing Engine</b></TableCell>
                <TableCell><b>Stop Number</b></TableCell>
                <TableCell><b>Import Status</b></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {routes.sort((a, b) => a.stop_number - b.stop_number).map((trip_route) => (
                <TableRow key={trip_route.id}>
                    <TableCell>{trip_route.id}</TableCell>
                    <TableCell>{trip_route.routing_engine || 'HERE Standard'}</TableCell>
                    <TableCell>{trip_route.stop_number}</TableCell>
                    <TableCell>{trip_route.import_status || "-"}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
)

export const TripRoutesForm = (props) => {
    const [username, setUsername] = useState(localStorage.getItem("TR_username") || "");
    const [clientKey, setClientKey] = useState(localStorage.getItem("TR_client_key") || "");
    const [reasonCodes, setReasonCodes] = useState({});
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [routes, setRoutes] = useState([]);
    const [requestSent, setRequestSent] = useState(false)
    const [expandedRow, setExpandedRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const dataProvider = useDataProvider();

    useEffect(() => {
        const now = new Date();
        const twoWeeksAgo = new Date(now);
        twoWeeksAgo.setDate(now.getDate() - 14);
        setStart(twoWeeksAgo.toISOString());
        setEnd(now.toISOString());
    }, []);

    const handleClear = () => {
        setRequestSent(false);
        localStorage.removeItem("TR_client_key")
        localStorage.removeItem("TR_username")
        setClientKey("");
        setUsername("");
        setRoutes([]);
    }
    const submitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);

        // Field validation
        const fields = { clientKey, username, start, end };
        for (const [key, value] of Object.entries(fields)) {
            if (!value) {
                notify(`${key} is required`, { type: 'error' });
                setLoading(false);
                return;
            }
        }

        // Proceed to fetch data if all fields are valid
        try {
            const { data } = await dataProvider.getList('trip_routes', {
                filter: { clientKey, username, start, end },
            });
            setRequestSent(true);
            setRoutes(data);
        } catch (error) {
            notify(`Error fetching trip routes: ${error.message}`, { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const fetchReasonCodes = async (uuid, loadId) => {
        // Only fetch if not already loaded
        if (!reasonCodes[uuid]) {
            try {
                const { data } = await dataProvider.getList('trip_plans', {
                    filter: { clientKey, username, loadId },
                });

                const item = data.find(item => item.uuid === uuid);
                const codes = item ? item.reason_codes.join(", ") : "No matching reason codes found.";
                setReasonCodes(prev => ({ ...prev, [uuid]: codes }));
            } catch (err) {
                notify(`Error retrieving reason codes: ${err.message}`, { type: 'error' });
                setReasonCodes(prev => ({ ...prev, [uuid]: "Error loading reason codes" }));
            }
        }
    };

    const RenderReasonCodes = ({ uuid, reasonCodes }) => {
        return (
            <TableRow>
                <TableCell />
                <TableCell colSpan={3}>
                    <b>{"Snapshot Reason Code(s): "}</b>{reasonCodes[uuid] || <CircularProgress size={20} />}
                </TableCell>
            </TableRow>
        );
    };

    const toggleExpandRow = (uuid, loadId) => {
        setExpandedRow(expandedRow === uuid ? null : uuid);
        if (expandedRow !== uuid) fetchReasonCodes(uuid, loadId);
    };

    const RenderTripRoutes = () => {
        // Group routes by trip_plan_uuid
        const groupedRoutes = routes.reduce((acc, route) => {
            if (!acc[route.trip_plan_uuid]) acc[route.trip_plan_uuid] = [];
            acc[route.trip_plan_uuid].push(route);
            return acc;
        }, {});

        return (
            <TableContainer>
                {Object.keys(groupedRoutes).map((trip_plan_uuid, index) => (
                    <Table key={trip_plan_uuid} component={Paper} elevation={3} style={{ margin: "10px" }}>
                        <TableHead>
                            <TableRow hover selected>
                                <TableCell>
                                    <Tooltip title="More Details">
                                        <IconButton onClick={() => toggleExpandRow(trip_plan_uuid, groupedRoutes[trip_plan_uuid][0].load_reference)}>
                                            {expandedRow === trip_plan_uuid ? <ExpandMore /> : <ChevronRight />}
                                        </IconButton>
                                    </Tooltip>

                                </TableCell>
                                <TableCell><b>Snapshot UUID</b></TableCell>
                                <TableCell><b>Load ID</b></TableCell>
                                <TableCell><b>Created At</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell />
                                <TableCell>{trip_plan_uuid}</TableCell>
                                <TableCell>{groupedRoutes[trip_plan_uuid][0].load_reference}</TableCell>
                                <TableCell>{groupedRoutes[trip_plan_uuid][0].created_at}</TableCell>
                            </TableRow>
                            {expandedRow === trip_plan_uuid && (
                                <React.Fragment>
                                    <RenderReasonCodes uuid={trip_plan_uuid} reasonCodes={reasonCodes} />
                                    <TableRow>
                                        <TableCell />
                                        <TableCell colSpan={3}>
                                            <RenderTripRoute routes={groupedRoutes[trip_plan_uuid]} />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell />
                                <TableCell>
                                    <TripRouteDownload label={"Snapshot JSON"} trip_plan_uuid={trip_plan_uuid} loadId={groupedRoutes[trip_plan_uuid][0].load_reference} fileType={'snapshot'} username={username} clientKey={clientKey} />
                                </TableCell>
                                <TableCell>
                                    <TripRouteDownload label={"Load JSON"} trip_plan_uuid={trip_plan_uuid} loadId={groupedRoutes[trip_plan_uuid][0].load_reference} fileType={'loadData'} username={username} clientKey={clientKey} />
                                </TableCell>
                                <TableCell>
                                    <TripRouteDownload label={"GPX File"} trip_plan_uuid={trip_plan_uuid} loadId={groupedRoutes[trip_plan_uuid][0].load_reference} fileType={'gpx'} username={username} clientKey={clientKey} />
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                ))}
            </TableContainer>
        );
    };


    const RenderResults = () => {
        if (loading) return <CircularProgress />;
        return requestSent ? (routes.length > 0 ? <RenderTripRoutes /> : <Typography>No Trip Routes Found</Typography>) : (<></>)
    }

    return (
        <div style={{ marginLeft: '5%', marginRight: '5%', textAlign: 'center' }}>
            <h2>Trip Routes</h2>
            <form onSubmit={submitHandler}>
                <div style={{ margin: "8px" }}>
                    <TextField
                        variant="outlined"
                        label="Client Key"
                        value={clientKey}
                        size="small"
                        onChange={(e) => { setClientKey(e.target.value); localStorage.setItem("TR_client_key", e.target.value) }}
                        style={{ width: '30%' }}
                    />
                </div>
                <div style={{ margin: "8px" }}>
                    <TextField
                        variant="outlined"
                        label="Username"
                        value={username}
                        size="small"
                        onChange={(e) => { setUsername(e.target.value); localStorage.setItem("TR_username", e.target.value) }}
                        style={{ width: '30%' }}
                    />
                </div>
                <div style={{ margin: "8px" }}>
                    <TextField
                        variant="outlined"
                        label="Start Date"
                        type="datetime-local"
                        value={start.slice(0, -1)}
                        onChange={(e) => setStart(e.target.value + "Z")}
                        style={{ width: '30%' }}
                    />
                </div>
                <div style={{ margin: "8px" }}>
                    <TextField
                        variant="outlined"
                        label="End Date"
                        type="datetime-local"
                        value={end.slice(0, -1)}
                        onChange={(e) => setEnd(e.target.value + "Z")}
                        style={{ width: '30%' }}
                    />
                </div>
                <div style={{ margin: "8px" }}>
                    <Button style={{ marginRight: '5px' }} type='submit' variant="contained" color="primary" label="Request" />
                    <Button style={{ marginLeft: '5px', backgroundColor: '#c8c8c8' }} onClick={handleClear} variant="outlined" label="Clear" />
                </div>
            </form>
            {RenderResults()}
        </div>
    )
}